.mix.portfolio-item {
  margin: 5px;
}
.checkmark::before {
  position: absolute;
  top: 9px;
  left: 19px;
  font-family: "Font Awesome 5 Free";
  content: "\f058";
  font-weight: 900;
  font-size: 28px;
  line-height: 3.4rem;
  color: #e80566;
}
.footer-img{
  height: 61px;
  margin-top: -10%;
}

.scroll-top.open {
  bottom: 111px;
}

.float{
  position:fixed;
  width:60px;
  height:60px;
  bottom:20px;
  left:1%;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
  margin-top:16px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #a9a9a9b3 !important;
}
.ReactModal__Content.ReactModal__Content--after-open {
    top: 0%!important;
    height: 100%;
}

@media (max-width:992px)
{
  .checkmark::before {
    position: absolute;
    top: 10px;
    left: 13px;
    font-family: "Font Awesome 5 Free";
    content: "\f058";
    font-weight: 900;
    font-size: 30px;
    line-height: 3.4rem;
    color: #e80566;
}
.scroll-top.open {
  right: 26px;
}
.portfolio-item, .gap {
  width: 98%;
}
.float{
  bottom: 21px;
}
}